import * as React from "react"
import ScrollAnimation from 'react-animate-on-scroll';

const Tagline = ({ tagline }) => {
    return (
        <div className="
            w-full 
            bg-blue-darker 
            py-14
            "
        >
            <ScrollAnimation animateIn="fadeIn" animateOnce>
                <h2 className="text-2xl sm:text-5xl text-center text-white px-8 xl:px-72 2xl:px-96">
                    {tagline}
                </h2>
            </ScrollAnimation>
        </div>
    )
}

export default Tagline